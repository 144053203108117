<template>
    <v-app style="background: transparent" :class="{}">

        <!-- begin:: Content Head -->
        <KTSubheader
                v-if="nav.subheaderDisplay"
                v-bind:breadcrumbs="nav.breadcrumbs"
                v-bind:title="nav.pageTitle"
        />
        <!-- end:: Content Head -->

        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container">

                <!--begin::Card-->
                <div class="card card-custom">
                    <div class="card-header flex-wrap border-0 pt-6 pb-0">
                        <div class="card-title">
                            <h3 class="card-label">
                                盘点清单
                                <span class="d-block text-muted pt-2 font-size-sm">
                                    <span style="margin-right:30px;">盘库编号：{{check.check_number}}</span>
                                    <span style="margin-right:30px;">仓库：{{check.warehouse_name}}</span>
                                    <span>盘库类型：{{getCheckType(check.type)}}</span>
                                </span>
                            </h3>
                        </div>
                        <div class="card-toolbar">

                        </div>
                    </div>
                    <div class="card-body">
                        <!--begin: Search Form-->

                        <!--begin::Search Form-->
                        <div class="mb-7">
                            <div class="row align-items-center">
                                <div class="col-lg-9 col-xl-8">
                                    <div class="row align-items-center">
                                        <div class="col-md-4 my-2 my-md-0">
                                            <div class="d-flex align-items-center">
                                                <label class="mr-3 mb-0 d-none d-md-block" style="width: 50px;">货架:</label>
                                                <b-form-select class="form-control" v-model="search_form.shelf_id" :options="shelf_list"  name="shelf_list">

                                                </b-form-select>
                                            </div>
                                        </div>
                                        <div class="col-md-4 my-2 my-md-0">
                                            <div class="d-flex align-items-center">
                                                <label class="mr-3 mb-0 d-none d-md-block" style="width: 50px;">状态:</label>
                                                <select class="form-control" v-model="search_form.status"  name="status">
                                                    <option value=""></option>
                                                    <option value="1">未盘点</option>
                                                    <option value="2">已盘点</option>
                                                </select>
                                            </div>
                                        </div>
                                        <a href="#" class="btn btn-light-primary px-6 font-weight-bold" v-on:click="searchList()">Search</a>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <!--end::Search Form-->
                        <!--end: Search Form-->


                        <KTDatatable
                                v-bind:list="list"
                                v-bind:column="column"
                                v-bind:showSelect="false"
                                v-bind:showPagination="true"
                                v-bind:pageSize="page.limit"
                                v-bind:pageCount="page.pageCount"
                                v-bind:total="page.total"
                                @operateHandler="operateHandler"
                                @pagechangeHandler="pagechangeHandler"
                        ></KTDatatable>

                    </div>
                </div>
                <!--end::Card-->
            </div>
            <!--end::Container-->
        </div>

        <v-dialog v-model="dialog_show" persistent max-width="700px">
            <component
                    :key="dialog_id"
                    :title="dialog_title"
                    :visible="dialog_show"
                    :is="dialog_view"
                    :dialog_data="dialog_data"
                    @opeareResultHandler="opeareResultHandler"></component>
        </v-dialog>

    </v-app>

</template>
<style lang="css">
    @import "~@/assets/css/common.css";
</style>
<script>
    import KTSubheader from "@/view/layout/subheader/Subheader.vue";
    import KTDatatable from "@/view/content/tables/Datatable.vue";
    import KTDatePicker from "@/view/content/date/DatePicker.vue";
    import addWareHouse from '@/view/pages/warehouse/AddWareHouse';
    import updateWareHouse from '@/view/pages/warehouse/UpdateWareHouse';
    import KTUtil from "@/assets/js/components/util";

    import {getShelf} from '@/api/warehouse';
    import {getCheckDetailList, getCheck, updateCheckDetail} from '@/api/checkwarehouse';

    export default {
        name: "warehouse_check_view",
        components: {
            KTSubheader,
            KTDatatable,
            KTDatePicker
        },
        created: function(){
            var _this = this;
            getCheck({'id': _this.$route.query.check_id}).then(function(res) {
                if(res && res.response) {
                    _this.check.warehouse_id = res.response.warehouse_id;
                    _this.check.warehouse_name = res.response.warehouse_name;
                    _this.check.check_number = res.response.check_number;
                    _this.check.type = res.response.type;

                    getShelf({'warehouse_id': _this.check.warehouse_id}).then(function(res) {
                        if(res && res.response) {
                            _this.shelf_list = KTUtil.listToSelectOptions(res.response, 'id', 'shelf_number');
                        }
                    })
                    _this.searchList();

                }
            });

        },
        data() {
            return {
                nav: {
                    subheaderDisplay: true,
                    breadcrumbs : [{'id':'', 'route':'/checkwarehouse', 'title':'仓库盘点'}, {'id':'', 'route':'/checkwarehouse/check', 'title':'产品盘点'}],
                    pageTitle: '仓库'
                },
                list: [],
                check: {
                    id: this.$route.query.check_id,
                    warehouse_id: '',
                    warehouse_name: '',
                    check_number: '',
                    type: ''
                },
                shelf_list: [],
                column: [{
                    field: 'product_title',
                    title: '产品',
                    width: 380,
                    formatter: function(row) {
                        if(row.image_url){
                            var html =
                                '<div class="d-flex align-items-center">' +
                                '<div class="symbol symbol-80 flex-shrink-0">' +
                                '<div class="symbol-label" style="background-image:url('+ row.image_url +')"></div>' +
                                '</div>' +
                                '<div class="ml-2">' +
                                '<div class="text-dark-75 font-weight-bold line-height">'+ row.product_title+'</div>' +
                                '<div class="text-dark-65 font-weight-bold line-height">'+ row.sku+'</div>' +
                                '<div class="text-dark-65 font-weight-bold line-height">'+ row.material_number+'</div>' +
                                '<div class="line-height">';
                            var attr = JSON.parse(row.attr_info);
                            for(var key in attr){
                                html += '<span class="label label-primary label-inline font-weight-lighter mr-2" title="'+ key +'">'+ attr[key] +'</span>';
                            }
                            html +=       '</div>'+
                                '</div>' +
                                '</div>';
                            return html;
                        } else {
                            return '';
                        }
                    }
                },{
                    field: 'shelf_number',
                    title: '货架'
                },{
                    field: 'begin_num',
                    title: '库存量'
                },{
                    field: 'end_num',
                    title: '盘点量',
                },{
                    field: 'wc',
                    title: '盘点误差',
                    formatter: function(row) {
                        if(row.end_num != null && row.end_num != 'null'){
                            if(row.begin_num < row.end_num) {
                                return '<span class="text-primary">盘盈 '+ (row.end_num - row.begin_num)  +'</span>';
                            } else if(row.begin_num > row.end_num){
                                return '<span class="text-danger">盘亏 '+ (row.end_num - row.begin_num)  +'</span>';
                            }
                        }
                    }
                },{
                    field: 'note',
                    title: '备注',
                    width: 150,

                }],
                search_form: {
                    shelf_id: '',
                    status: ''
                },
                page: {
                    currPage: 1,
                    limit: 15,
                    pageCount:0,
                    total:0,
                },
                dialog_id: 0,
                dialog_show: false,
                dialog_title: '',
                dialog_view: null,
                dialog_data: null
            }
        },
        watch: {

        },
        methods: {
            searchList() {
                var _this = this;
                var data = _this.search_form;
                data['check_id'] = _this.$route.query.check_id;
                data['page'] = _this.page.currPage;
                data['limit'] = _this.page.limit;
                getCheckDetailList(data).then(function(res) {
                    if(res && res.response) {
                        _this.list = res.response.list;
                        _this.page.total = res.response.total;
                        _this.page.pageCount = res.response.count;
                    }
                });
            },
            pagechangeHandler(page, pageSize) {
                this.page.currPage = page;
                this.page.limit = pageSize;
                this.searchList();
            },
            opeareResultHandler(result) {
                if(result){
                    this.searchList();
                }
                this.dialog_show = false;
            },
            getCheckType(type) {
                if (type == 1) {
                    return '整仓盘库';
                } else if (type == 2) {
                    return '按货架盘点';
                } else if (type == 3) {
                    return '按产品盘点';
                }
            },
            operateHandler() {}
        }
    }
</script>
