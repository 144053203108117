import request from "./request";

export function addWareHouse(params){
    return request({
        url: 'warehouse/addWareHouse',
        method: 'post',
        data: params
    });
}


export function getWareHouseList(params){
    return request({
        url: 'warehouse/getWareHouseList',
        method: 'post',
        data: params
    });
}

export function updateWareHouse(params){
    return request({
        url: 'warehouse/updateWareHouse',
        method: 'post',
        data: params
    });
}

export function deleteWareHouse(params){
    return request({
        url: 'warehouse/deleteWareHouse',
        method: 'post',
        data: params
    });
}


export function getWareHouseShelf(params){
    return request({
        url: 'warehouse/getWareHouseShelf',
        method: 'post',
        data: params
    });
}



export function addShelf(params){
    return request({
        url: 'warehouse/addShelf',
        method: 'post',
        data: params
    });
}

export function updateShelf(params){
    return request({
        url: 'warehouse/updateShelf',
        method: 'post',
        data: params
    });
}

export function getWareHouse(params){
    return request({
        url: 'warehouse/getWareHouse',
        method: 'post',
        data: params
    });
}

export function getShelfList(params){
    return request({
        url: 'warehouse/getShelfList',
        method: 'post',
        data: params
    });
}


export function deleteShelf(params){
    return request({
        url: 'warehouse/deleteShelf',
        method: 'post',
        data: params
    });
}

export function batchAddShelf(params){
    return request({
        url: 'warehouse/batchAddShelf',
        method: 'post',
        data: params
    });
}

export function getShelf(params){
    return request({
        url: 'warehouse/getShelf',
        method: 'post',
        data: params
    });
}


