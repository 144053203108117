<template>
    <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
    >
        <template v-slot:activator="{ on, attrs }">
            <input
                    type="text"
                    class="form-control"
                    placeholder="请选择日期"
                    v-model="date"
                    readonly
                    v-bind="attrs"
                    v-on="on"

            >
        </template>
        <v-date-picker
                v-model="date"
                no-title
                color="#1BC5BD"
                @input="menu = false"
        >
        </v-date-picker>
    </v-menu>

</template>
<script>
    export default {
        name: "DatePicker",
        model: {
            prop: 'modelVal',
            event: 'change'
        },
        props:{
            modelVal: String
        },
        data(){
            return {
                menu: false,
                date: ''
            }
        },
        watch:{
            date(value) {
                this.$emit('change',value);
            }
        }
    }
</script>

