import request from './request';

export function getCheckCount(params){
    return request({
        url: 'checkwarehouse/getCheckCount',
        method: 'post',
        data: params
    });
}

export function addCheck(params){
    return request({
        url: 'checkwarehouse/addCheck',
        method: 'post',
        data: params
    });
}

export function getCheckList(params){
    return request({
        url: 'checkwarehouse/getCheckList',
        method: 'post',
        data: params
    });
}

export function getCheckDetailList(params){
    return request({
        url: 'checkwarehouse/getCheckDetailList',
        method: 'post',
        data: params
    });
}

export function deleteCheckDetail(params){
    return request({
        url: 'checkwarehouse/deleteCheckDetail',
        method: 'post',
        data: params
    });
}

export function addCheckDetail(params){
    return request({
        url: 'checkwarehouse/addCheckDetail',
        method: 'post',
        data: params
    });
}

export function deleteCheck(params){
    return request({
        url: 'checkwarehouse/deleteCheck',
        method: 'post',
        data: params
    });
}

export function updateCheckStatus(params){
    return request({
        url: 'checkwarehouse/updateCheckStatus',
        method: 'post',
        data: params
    });
}


export function getCheck(params){
    return request({
        url: 'checkwarehouse/getCheck',
        method: 'post',
        data: params
    });
}

export function updateCheckDetail(params){
    return request({
        url: 'checkwarehouse/updateCheckDetail',
        method: 'post',
        data: params
    });
}

export function endCheck(params){
    return request({
        url: 'checkwarehouse/endCheck',
        method: 'post',
        data: params
    });
}

